import useAOS from "./aos";
import RoutesWrapper from "./routes";
import { App as AntdApp } from "antd";

function App() {
  useAOS();

  return (
    <AntdApp>
      <RoutesWrapper />
    </AntdApp>
  );
}
export default App;
